.login_main_container{
    position: relative;
    width: 100vw; /* Set your desired width */
    height: 100vh; /* Set your desired height */
    overflow: hidden;
}
.login_main_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/background_image.png');
    background-size: cover;
    background-position: center;
    /* opacity: 0.6; */
    z-index: 1;
    overflow: hidden;
}

.login_main_container > * {
    position: relative;
    z-index: 2;
}
.login_sub_container{
    background-color: rgba(0, 0, 0, 0.452);
    width: 100vw; 
    height: 100vh;
    position: relative;
}