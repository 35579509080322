.flight_ticket_cancellation_summary_main_container {
    margin-top: 30px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
}
.flight_ticket_cancellation_summary_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}
.flight_ticket_cancellation_summary_fair_grid{
    display: grid;
    grid-template-columns: 20% auto;
}
.flight_ticket_cancellation_summary_text{
    color: #074D4D;
    font-size: 16px;
    font-weight: 600;
}