.sales_report_main_container {
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
    overflow: auto;
    /* max-width: 80vw; */
}

.sales_report_title {
    font-size: 25px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}

.sales_report_header {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sales_excel_icon{
    color: green;
}