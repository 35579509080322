.tax_settings_form_main_container {
    margin-top: 30px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
    /* display: grid; */
    /* grid-template-columns: auto auto auto; */
    /* grid-gap: 20px; */
}

.tax_settings_form_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
}
.tax_details_grid_row_alignment{
    display: grid;
    grid-template-columns: 10% 20% 10%;
    margin: 10px 0px;
}

.tax_details_update_button{
    padding: 6px 10px;
    border: 2px solid #074D4D ;
    color: #074D4D;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
}
.tax_details_field_action_row_alignment{
    display: flex;
    align-items: center;
}
.tax_details_edit_icon{
    font-size: 25px;
    color: #FFAC27;
    cursor: pointer;
}

.tax_details_save_icon{
    font-size: 26px;
    color: #074D4D;
    cursor: pointer;
}

.tax_details_cancel_icon{
    font-size: 25px;
    color: red;
    cursor: pointer;
}