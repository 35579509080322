/* for status card style */
.box-container {
    border: 2px solid #ddd;
    border-radius: 12px;
    padding: 16px;


}

.status-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-around;
}

.status-card {
    flex: 1 1 calc(25% - 16px);
    max-width: calc(25% - 16px);
    padding: 16px;
    border-radius: 12px;
    text-align: center;

}

.status-label {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
}

.status-count {
    font-size: 32px;
    font-weight: bold;
    color: #444;
}

@media (max-width: 768px) {
    .status-card {
        flex: 1 1 calc(50% - 16px);
        max-width: calc(50% - 16px);
    }
}

@media (max-width: 480px) {
    .status-card {
        flex: 1 1 100%;
        max-width: 100%;
    }
}