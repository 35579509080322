.flight_cancel_details_main_container{
    display: grid;
    grid-template-columns: auto auto;
    gap:10px
}
.flight_cancel_details_sub_container{
    display: grid;
    grid-template-columns: 60% auto;
    margin: 5px 0px;
}

.flight_cancel_details_main_legent_container {
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
}

.flight_cancel_details_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}
.flight_cancel_details_label{
    font-size: 16px;
    font-weight: 500;
}
.flight_cancel_details_label_price{
    font-size: 16px;
    font-weight: 600;
    text-align: right;
}