
.flight_ticket_passenger_table_header_component{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:#074D4D ;
    padding: 8px 8px;
}
.flight_ticket_passenger_add_passenger_action_container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}
.flight_ticket_passenger_table_action_add{
    font-size: 20px;
    color: white;
    cursor: pointer;
}
.flight_ticket_passenger_table_action_edit{
    font-size: 20px;
    color: #FFAC27;
    cursor: pointer;
}
.flight_ticket_passenger_table_action_delete{
    font-size: 20px;
    color: #FF2222;
    cursor: pointer;
}
.flight_ticket_passenger_table_header_action_container{
    display: flex;
    gap: 5px;
    align-items: center;
    background-color:#074D4D ;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.flight_ticket_passenger_table_action_text{
    font-size: 14px;
    font-weight: 500;
    color: white;

}
.flight_ticket_passenger_table_header_text{
    font-size: 15px;
    font-weight: 500;
    color: white;
}

.table_legent_main_container{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
}
.table_legent_cancel_icon{
    font-size: 18px;
    color: #FF2222;
}
.table_legent_cancel_text{
    font-size: 15px;
    color: #FF2222;
}

.table_legent_date_change_icon{
    font-size: 18px;
    color: orange;
}
.table_legent_servicefee_icon{
    font-size: 18px;
    color: blue; 
}
.table_legent_servicefee_text{
    font-size: 15px;
    color: blue;
}
.table_legent_date_change_text{
    font-size: 15px;
    color: orange;
}

.table_legent_servicefee {
    display: flex;
    align-items: center; /* Align icon and text vertically */
}
.table_legent_servicefee_total {
    margin: 0; /* Reset margin for consistency */
    font-size: 14px; /* Same size as text */
    color: #666; /* Slightly different color to differentiate */
}
.passenger_table_footer_action_main_container{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.passenger_table_summary_action_container{
    display: flex;
    gap: 10px;
}
.passenger_table_action_summary_button{
    font-size: 15px;
    cursor: pointer;
    color: #074D4D;
    font-weight: 600;
    text-decoration: underline;
}
