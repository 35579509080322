.auditlog_module_main_container {
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
}

.auditlog_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}

.auditlog_module_filter {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.auditlog_table_main_container {
    margin-top: 20px;
}



.auditlog_filter_sub_date_selection_container {
    border: 1px solid #C0C0C0;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    border-radius: 5px;
}

.auditlog_filter_legent_style_1 {
    font-family: 'Poppins';
    font-size: 14px;
    color: #929292;
    z-index: 10;
    position: absolute;
    top: -10px;
    /* left: 12px; */
    background-color: white;
}

.auditlog_filter_legent_style_2 {
    font-family: 'Poppins';
    font-size: 14px;
    color: red;
    z-index: 20;
    position: absolute;
    top: -10px;
    left: 270px;
    background-color: red;
}

.auditlog_filter_sub_container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
