.transaction_report_main_container{
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
    overflow: auto;
}

.transaction_report_grid_container{
   display: grid;
   grid-template-columns: 200px auto;
   margin: 10px 0px;
}
.transaction_report_main_heading{
    margin: 10px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.transaction_report_label{
    color: black;
    font-weight: 600;
    font-size: 16px;
}
.transaction_report_period_container{
    text-align: center;
    margin: 15px 0px;
}

.transaction_no_data_container{
    position: relative;
    /* width: 99%; */
    height: 200px;
    text-align: center;
    border: 1px solid rgb(252, 252, 252);
  }
  .no_data_sub_container {
    position: absolute;
    top: 50%;
    left: 50%;
  }