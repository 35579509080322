.custom_simple_main_container table {
    width: 100%;
    border: 1px solid rgb(172, 172, 172);
    border-collapse: collapse;
    font-weight:600;
    font-family: "Jost";
    font-size: 14px;
    text-align: left;
}

.custom_simple_main_container table th,
.custom_simple_main_container table td {
    border: 1px solid rgb(172, 172, 172);
    padding: 8px;
}
.custom_simple_sub_rows_container{
    font-weight:400;
    font-family: "Jost";
    font-size: 14px
}