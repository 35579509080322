.flight_ticket_additional_info_main_container{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
}
.flight_ticket_additional_info_main_text {
    font-size: 16px;
    font-weight: 400;
    color: #767676;
    margin-top: 15px;
    
}

.flight_ticket_choose_file_field {
    border: 1px solid #C0C0C0;
    font-family: 'Jost';
    font-size: 15px;
    font-weight: 400;
    background-color: #F5F5F5;
    color: #655555;
    padding: 5px 10px;

}
.flight_ticket_additional_info_attchment_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F5F5F5 ;
    padding: 4px;
}
.flight_ticket_additional_info_attchment_main_container{
    max-height: 130px;
    overflow: auto;
    padding-right: 10px;
}
.flight_ticket_additional_info_attchment_item_action{
    display: flex;
    align-items: center;
    gap: 10px;
}
.flight_ticket_additional_info_attchment_download{
    font-size: 20px;
    color: #02a332;
    cursor: pointer;
}

.flight_ticket_additional_info_attchment_delete{
    font-size: 20px;
    color: #FF2222;
    cursor: pointer;
}
.flight_ticket_additional_info_attchment_item_text{
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
}