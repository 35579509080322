.CustomPopOverMenu_MainConatiner{
    position: absolute;
    top: 15px;
    left: 60px;
    max-width: 150px;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 8px 10px;
    z-index: 9999;
}
.CustomPopOverMenu_MainConatiner:before{
    content: "";
    position: absolute;
    top: 1px;
    left:-12px;
    z-index: 9999;
    border: solid 8px transparent;
    border-right-color: #FFF;
}
.CustomPopOverMenu_Text{
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    color: black;
    white-space: nowrap;
     z-index: 9999;
}