.review_history_item_container{
    display: grid;
    grid-template-columns: 10px auto;
    gap: 5px;
    margin-bottom: 7px;
    font-size: 15px;
}
.review_history_item_empty{
    font-size: 15px;
    text-align: center;
}