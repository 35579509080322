.flight_ticket_filter_sub_container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px 0px;
}

.cab_filter_sub_container {
    display: grid;
    grid-template-columns: 15% 20% 21% 18% 20% 19% 25%;
    gap: 10px;
}

.user_filter_actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user_filter_action_reset {
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    height: 100%;
    padding: 0px 18px;
    cursor: pointer;
}

.user_filter_action_search {
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    height: 100%;
    padding: 0px 18px;
    cursor: pointer;
}

.filter_action_icons {
    font-size: 20px;
    color: #074D4D;
    cursor: pointer;
}

/*  */
.flight_ticket_filter_sub_date_selection_container {
    border: 1px solid #C0C0C0;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    border-radius: 5px;
}

.flight_ticket_filter_legent_style_1 {
    font-family: 'Poppins';
    font-size: 14px;
    color: #929292;
    z-index: 10;
    position: absolute;
    top: -10px;
    left: 12px;
    background-color: white;
}

.flight_ticket_filter_style_1 {
    width: 240px;
}

.flight_ticket_filter_actions {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.flight_ticket_filter_action_icons {
    font-size: 20px;
    color: #074D4D;
    cursor: pointer;
}

.flight_ticket_filter_action_reset {
    cursor: pointer;
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    height: 100%;
    padding: 0px 18px;
}

.flight_ticket_filter_action_search {
    cursor: pointer;
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    height: 100%;
    padding: 0px 18px;
}

.flight_ticket_filter_sub_date_selection_container {
    border: 1px solid #C0C0C0;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    border-radius: 5px;
}

.flight_ticket_filter_legent_style_1 {
    font-family: 'Poppins';
    font-size: 14px;
    color: #929292;
    z-index: 10;
    position: absolute;
    top: -10px;
    left: 12px;
    background-color: white;
}

.flight_ticket_filter_legent_style_2 {
    font-family: 'Poppins';
    font-size: 14px;
    color: #929292;
    z-index: 20;
    position: absolute;
    top: -10px;
    left: 270px;
    background-color: white;
}