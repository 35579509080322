.cab_cancel_form_row_alignment {
    display: grid;
    grid-template-columns: 150px auto;
    margin-bottom: 10px;
}

.cab_cancel_form_label_text {
    font-size: 16px;
    font-weight: 400;
}

.cab_cancel_form_label_total_text {
    font-size: 16px;
    font-weight: 500;
}
.cancel_main_container {
    display: grid;
    gap: 2rem;
    grid-template-columns: auto auto;
}

.invalid_refund_warning {
    display: flex;
    justify-content: center;
    color: #d32f2f;
    font-weight: 600;
}