.flight_ticket_module_main_container {
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
    overflow: auto;
}

.flight_ticket_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}

.flight_ticket_module_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flight_ticket_add_button {
    padding: 6px 15px;
    background-color: #ff8848;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    cursor: pointer;
    margin-top: 3px;
    margin-bottom: 10px;
    margin-right: 10px;
}


.flight_ticket_action_edit {
    font-size: 20px;
    color: #FFAC27;
    cursor: pointer;
}

.flight_ticket_action_cancel_ticket {
    font-size: 20px;
    color: #af2121;
    cursor: pointer;
}

.flight_ticket_action_view {
    font-size: 20px;
    color: #046aa5;
    cursor: pointer;
}

.flight_ticket_action_re_shedule {
    font-size: 18px;
    color: #04a511;
    cursor: pointer;
}

.flight_ticket_action_delete {
    font-size: 20px;
    color: #ff0000;
    cursor: pointer;
}

.flight_ticket_action_approve {
    font-size: 20px;
    color: #04a511;
    cursor: pointer;
}

.flight_ticket_action_reject {
    font-size: 20px;
    color: #ff0000;
    cursor: pointer;
}

.flight_ticket_action_comment {
    font-size: 16px;
    color: #008cff;
    cursor: pointer;
}

.flight_ticket_action_onhold {
    font-size: 18px;
    color: #FFAC27;
    cursor: pointer;
}

.flight_ticket_action_verified {
    font-size: 17px;
    color: #04a511;
    cursor: pointer;
}

.flight_ticket_action_invoice {
    font-size: 20px;
    color: #4d4d4d;
    cursor: pointer;
}

.flight_ticket_action_amendment {
    font-size: 20px;
    color: #bb1b1b;
    cursor: pointer;
}

.flight_ticket_action_cash {
    font-size: 18px;
    color: #008cff;
    cursor: pointer;
}