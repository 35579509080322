.custom_delete_confirm_modal_action_container{
    display: flex;
    justify-content: end;
    gap:10px
}
.custom_delete_confirm_modal_text{
    font-size: 15px;
}
.custom_delete_confirm_modal_yes_action{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.custom_delete_confirm_modal_no_action{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}