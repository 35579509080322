.header_notification_listing_main_container{
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    min-width: 200px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* min-height: 40px;
    max-height: 400px; */
    overflow: auto;
}
.header_notification_list_sub_container{
    border: 1px solid #074D4D;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 5px;
    min-width: 300px;
    cursor: pointer;
}
.header_notification_list_flex{
    display: flex;
    align-items: center;
    gap: 10px;
}
.header_notification_user_details_main_container_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}
.header_notification_user_name{
    font-size: 14px;
    font-weight: 600;
    color: black;
}
.header_notification_date_time{
    font-size: 12px;
    font-weight: 500;
    color: rgb(116, 107, 107);
}
.header_notification_message{
    font-size: 15px;
    color: black;
}
.header_notification_not_found{
    text-align: center;
    font-size: 14px;
    color: black;
}
.header_notification_view_all_container{
    border: 1px dashed rgb(214, 214, 214);
    border-radius: 5px;
    padding: 5px;
}
.notification_view_all_text{
    text-align: center;
    color: #074D4D;
    font-weight: bold;
    cursor: pointer;

}