.login_form_main_container {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 60%;
    transform: translateY(-50%);
    min-width: 450px;
    padding: 30px;
    border-radius: 10px;
}

.login_form_sub_container_1 {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login_form_las_logo {
    width: 220px;
    height: 50px;
}

.login_form_m2_logo {
    width: 70px;
    height: 60px;
    object-fit: contain;
}

.login_form_sub_container_2 {
    margin: 20px 0px;
}

.login_form_heading_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 34.68px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #000000;
}

.login_form_sub_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 23.12px;
    text-align: left;
    color: #000000BF;
}

.form_label {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0px;
    text-align: left;
    color: #000000;
}

.form_input_container {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #C9C9C9;
    border-radius: 5px;
}

.form_input {
    width: 100%;
    border: none;
    outline: none;
}

.login_form_remember_forget_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.login_form_remember_me_container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.login_form_remember_me_text {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.79px;
    text-align: left;
    color: #000000D9;
}

.login_form_forget_text {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.79px;
    text-align: left;
    color: #000000D9;
    text-decoration: underline;
    cursor: pointer;
}

.login_form_sign_up_container {
    margin: 20px 0px;
    padding: 10px;
    background-color: #074D4D;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.12px;
    color: white;
    border: none;
}

.login_form_back_to_sign_in_container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .login_form_main_container {
        position: absolute;
        background-color: white;
        top: 50%;
        left: 0%;
        transform: translateY(-50%);
        border-radius: 10px;
        min-width: 78%;
        margin: 1rem;
    }

    .login_form_las_logo,
    .login_form_m2_logo {
        max-width: 80px;
    }

    .login_form_heading_title {
        font-size: 1.25rem;
    }

    .login_form_sub_title {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .login_form_sub_container_1 {
        flex-direction: column;
        align-items: center;
    }

    .login_form_remember_forget_container {
        flex-direction: column;
        gap: 10px;
    }
}