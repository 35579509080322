.flight_ticket_date_change_form{
 margin-top: 10px;
}
.flight_ticket_date_change_form_main_container {
    position: relative;
    border: 1px solid #074D4D;
    padding: 15px;
}
.flight_ticket_date_change_form_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}
.flight_ticket_date_change_form_sub_container_0{
    /* margin-top: 15px; */
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    /* border: 1px solid #074D4D; */
    padding: 15px;
}
.flight_ticket_date_change_form_label{
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #767676;
    margin-bottom: 8px;
}
.flight_ticket_date_change_form_action_container{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}
.flight_ticket_date_change_form_single_row_alignment {
    display: flex;
    align-items: center;
    gap: 20px;
}
.flight_ticket_date_change_form_reset_button{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.flight_ticket_date_change_form_save_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}