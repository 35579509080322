.header_main_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #074D4D;
    padding: 10px 20px;
    border-radius: 20px;
    margin-left: 20px;
}

.header_profile_main_container {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.header_profile_pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.header_role_dropdown_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_hamburger_menu {
    cursor: pointer;
    font-size: 25px;
    color: white;
}

.header_user_name {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    color: white;
}

.header_role {
    font-size: 14px;
    font-weight: 300;
    line-height: 20.23px;
    color: white;
}

.header_dropdown {
    font-size: 20px;
    color: white;
}

.header_user_details {
    cursor: pointer;
}

.header_profile_based_menus {
    position: absolute;
    background-color: white;
    padding: 10px;
    top: 80px;
    right: 30px;
    border-radius: 10px;
    z-index: 10;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header_profile_based_menus_container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px;
    cursor: pointer;
}

.header_profile_icon {
    width: 13px;
    height: 13px;
}

.header_profile_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.23px;
    text-align: left;
}

.user_profile_sub_icon_container{
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 50%;
    cursor: pointer;
}

.user_profile_edit_icon{
    font-size: 20px;
    color: orange;
    cursor: pointer;
}
.user_profile_with_edit_actions{
    display: flex;
    gap: 10px;
    align-items: center;
}
.notification_icon{
    cursor: pointer;
    color: white;
    font-size: 30px;
}
.header_profile_notification_main_container{
    position: relative;
}
.header_profile_notification_container{
    position: absolute;
    right: 0;
    z-index: 99;
    min-height: 50px;
    max-height: 500px;
    overflow: auto;
}