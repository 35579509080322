.email_notification_form_main_container {
    margin-top: 30px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
}
.email_notification_form_main_container_without_grid {
    margin-top: 30px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
}
.email_notification_form_legend {
    padding: 0px 8px;
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
}

.email_notification_form_save_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 5px 30px;
}

.email_context_list_item{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 7px 0px;
}
.email_notification_sub_main_container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.email_main_container{
    min-width: 300px;
}
.email_listing_container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.email_list_item{
    border: 1px solid #074D4D;
    padding: 10px;
}
.email_notification_action_container{
    display: flex;
    justify-content: flex-end;
}
.email_notification_delete_icon{
    color: red;
    font-size: 20px;
}
.email_label_text{
    font-size: 18px;
    font-weight: 500;
    color: #074D4D;
}
.email_save_icon_button{
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 50%;
    cursor: pointer;
}
.email_save_icon{
    color: #074D4D;
    font-size: 25px;
}
.email_text_container{
    border: 1px solid rgb(189, 189, 189);
    padding: 5px 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.email_notification_sub_main_container_with_action{
    display: flex;
    gap: 10px;
    align-items: center;
}
.email_listing_main_container{
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 500px;
}
.email_clear_icon{
    color:gray;
    font-size: 20px;
    cursor: pointer;
}

.email_context_sub_container {
    margin-top: 10px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
    display: grid;
}

.email_context_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}
.email_context_listing_container{
    display: flex;
    gap: 10px;
}
.email_context_with_check_box{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px 0px;
}
.email_notification_action_button_container{
    display: flex;
    justify-content: flex-end;
}