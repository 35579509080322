.no_data_container{
    position: relative;
    width: 100%;
    height: 200px;
    text-align: center;
    border: 1px solid rgb(252, 252, 252);
  }
  .no_data_sub_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .no_data_image{
    width: 250px;
    height: 180px;
  }
  .custom_table_expand_icon{
    font-size: 18px;
    color:#074D4D ;
  }
  .nested_table_header_title{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color:#074D4D ;
    margin-bottom: 10px;

  }
  .custom_table_expand_container{
    display: flex;
    align-items: center;
  }
 .highlight_icon{
   margin-top: 4px;
  }
  
  