.custom_table_column_selector_main_container {
    position: absolute;
    right: -15px;
    top: 100px;
}

.custom_table_column_sub_container {
    display: flex;
}

.custom_table_column_sub_setting_container_0 {
    position: relative;
    height: 50px;
    width: 18px;
    padding: 5px;
    background-color: #074D4D;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.custom_table_column_sub_setting_container_1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom_table_column_setting_icon {
    color: white;
    font-size: 18px;
}

.custom_table_column_items_main_container {
    z-index: 2;
    background-color: white;
    width: 150px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.custom_table_column_items_sub_container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.custom_table_column_items_main_container_title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.custom_table_column_items_sub_title {
    font-size: 15px;
    font-weight: 400;
}