.airline_add_container{
    display: flex;
    justify-content: flex-end;
    margin: 8px;
}
.airline_add_button{
    padding: 6px 15px;
    background-color: #ff8848;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    cursor: pointer;
}
.airline_delete{
    font-size: 20px;
    color: #ff0000;
    cursor: pointer;
}

.airline_edit{
    font-size: 18px;
    color: #FFAC27;
    cursor: pointer;
}

.airline_module_main_container{
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
    overflow: auto;
}