.custom_date_picker_container {
    margin-top: 5px;
    position: absolute;
    display: none;
    border: 0.5px solid #C0C0C0;
    z-index: 9999;
}

.custom_date_picker_field {
    position: relative;
    border: 0.5px solid #C0C0C0;
     height: 38px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
}

.custom_date_picker_placeholder {
    font-family: 'Poppins';
    color: #C0C0C0;
    font-size: 15px;
}

.custom_date_picker_action {
    margin-top: 5px;
    color: #C0C0C0;
    font-size: 18px;
    cursor: pointer;
}