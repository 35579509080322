.users_module_main_container{
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
}
.user_title{
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}
.user_module_filter{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user_add_button{
    padding: 6px 15px;
    background-color: #ff8848;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    cursor: pointer;
}
.user_table_main_container{
    margin-top: 20px;
}
.user_action_password{
    font-size: 18px;
    color: #02a332;
    cursor: pointer;
}
.user_action_edit{
    font-size: 20px;
    color: #FFAC27;
    cursor: pointer;
}
.user_action_delete{
    font-size: 20px;
    color: #FF2222;
    cursor: pointer;
}
.user_action_view{
    font-size: 20px;
    color: #046aa5;
    cursor: pointer;
}
.user_delete_modal_action_container{
    display: flex;
    justify-content: end;
    gap:10px
}
.user_delete_modal_text{
    font-size: 15px;
}
.user_delete_modal_yes_action{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
}

.user_delete_modal_no_action{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
}