.user_profile_super_container {
    /* display: flex;
    justify-content: center; */
}

.user_profile_main_container {
    position: relative;
    border-radius: 20px;
    padding: 20px;
}

.user_profile_sub_container {
    /* display: flex;
    align-items: center;
    gap: 20px; */
}

.user_profile_avatar_container {
    position: relative;
    display: flex;
    justify-content: center;
}

.user_profile_avatar {
    position: relative;
}

.user_profile_camera_icon_container {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 8px;
    border-radius: 50%;
}

.user_profile_camera_icon {
    font-size: 18px;
}

.change_password_container {
    margin: 10px;
    display: flex;
    justify-content: center;
    border: 1px solid green;
    padding: 5px 8px;
}

.user_profile_details {
    margin-top: 20px;
}

.user_profile_grid {
    margin: 10px 5px;
    display: grid;
    grid-template-columns: 150px auto;
    align-items: center;

}

.user_profile_label {
    font-size: 16px;
    font-weight: 500;
}

.user_profile_value {
    font-size: 16px;
}

.user_profile_icon_container {
    position: absolute;
    right: 10px;
    z-index: 99;
}

/* .user_profile_sub_icon_container{
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 50%;
    cursor: pointer;
} */
.user_profile_icond_flex_container {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* .user_profile_edit_icon{
     font-size: 20px;
     color: orange;
     cursor: pointer;
} */
.user_profile_password_icon {
    font-size: 20px;
    color: green;
    cursor: pointer;
}

.user_edit_form_single_row_alignment {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user_edit_form_cancel_button {
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.user_edit_form_save_button {
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.user_edit_form_action_container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.invalid_file_warning {
    display: flex;
    justify-content: center;
    color: red;
}