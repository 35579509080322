.package_supplier_table_header_component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #074D4D;
    padding: 8px 8px;
}

.package_supplier_add_passenger_action_container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.package_supplier_table_action_add {
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.package_supplier_table_action_edit {
    font-size: 20px;
    color: #FFAC27;
    cursor: pointer;
}

.package_supplier_table_action_delete {
    font-size: 20px;
    color: #FF2222;
    cursor: pointer;
}

.package_supplier_table_header_action_container {
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: #074D4D;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.package_supplier_table_action_text {
    font-size: 14px;
    font-weight: 500;
    color: white;

}

.package_supplier_table_header_text {
    font-size: 15px;
    font-weight: 500;
    color: white;
}

.table_legent_main_container {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.table_legent_cancel_icon {
    font-size: 18px;
    color: #FF2222;
}

.table_legent_cancel_text {
    font-size: 15px;
    color: #FF2222;
}

.table_legent_date_change_icon {
    font-size: 18px;
    color: orange;
}

.table_legent_date_change_text {
    font-size: 15px;
    color: orange;
}

.package_supplier_table_footer_action_main_container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.package_supplier_table_summary_action_container {
    display: flex;
    gap: 10px;
}

.package_supplier_table_action_summary_button {
    font-size: 15px;
    cursor: pointer;
    color: #074D4D;
    font-weight: 600;
    text-decoration: underline;
}