.scrollable-div {
    height: 86vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.header_notification_list_sub_container:hover {
    background: #38818133;
    cursor: pointer;
}

.dashboard-Highlight-div {
    height: 86vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.packages_module_main_container {
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
    overflow: auto;
}

.packages_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}

.packages_module_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.packages_add_button {
    padding: 6px 15px;
    background-color: #ff8848;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    cursor: pointer;
    margin-top: 3px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.packages_action_delete {
    font-size: 18px;
    color: #FFAC27;
    cursor: pointer;
}

.packages_action_edit {
    font-size: 20px;
    color: #FFAC27;
    cursor: pointer;
}

.packages_action_view {
    font-size: 20px;
    color: #046aa5;
    cursor: pointer;
}