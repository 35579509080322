.flight_payment_information_grid{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap:10px;
    margin: 6px 0px;
}
.flight_payment_information_attachment_remark_grid{
    display: grid;
    grid-template-columns: 16% auto;
    gap:10px;
    margin: 6px 0px;
}
.flight_payment_info_delete{
    color: red;
    font-size: 22px;
}
.flight_payment_info_container{
    display: flex;
    gap: 10px;
    align-items:center;
}
.flight_payment_info_attchment_item_text{
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.flight_payment_info_download_attachment_action_text{
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
}
.flight_payment_information_form_save_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    text-align: center;
    padding: 3px 20px;
    cursor: pointer;
}
.flight_payment_information_action{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.flight_payment_information_container{
    border: 1px solid #074D4D;
    padding: 10px;
    margin-bottom: 10px;
}