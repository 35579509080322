.custom_modal_multiple_header_text_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.custom_modal_multiple_header_text{
    font-size: 18px;
    font-weight: bold;
    color: #074D4D;
}
.custom_modal_multiple_header_sub_text{
    font-size: 17px;
    font-weight: 600;
    color: #074D4D;
}