.scrollable-container {
    height: calc(100vh - 100px);
    overflow-y: auto; /* Enable vertical scrolling */
  }
  .scrollable-container::-webkit-scrollbar {
    display: none; /* Hides scrollbar for Chrome, Safari, and Edge */
  }
.dashboard_main_container_0{
    background-color: white;
    padding: 15px;
    border-radius: 15px;
}
.dashboard_sub_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.dashboard_title_text_1{
    font-size: 20px;
    font-weight: 600;
    line-height: 34.68px;
    text-align: left;
    color: #074D4D;
}
.dashboard_card_listing_main_container{
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
}
.dashboard_row_alignment{
    display: flex;
    justify-content: space-between;
}
.dashboard_main_text{
    font-size: 16px;
    font-weight: 600;
    line-height: 25.21px;
    color: #878787;
}
.dashboard_sub_text{
    font-size: 16px;
    font-weight: 400;
    line-height: 25.21px;
    color: #878787;
}