.update_password_form {
    width: 500px;
  }
  .update_password_form_label {
    font-size: 15px;
    margin: 8px 0px;
    font-weight: 500;
  }
  
  .changePassword_form_action {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
  }
.update_password_form_single_row_alignment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}
.update_password_form_reset_button{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.update_password_form_update_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}