.finance_level_modal_form_single_row_alignment {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.finance_level_modal_form_reset_button {
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.finance_level_modal_form_update_button {
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.comment_error_message {
    color: red;
    font-size: 15px;
    margin-top: 5px;
}

.finance_level_modal_label {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #767676;
    margin: 8px 0px;
}

.flight_ticket_additional_info_attachment_item {
    font-size: 15px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}

.flight_ticket_additional_info_attachment_item_delete {
    font-size: 16px;
    color: red;
    cursor: pointer;
    text-decoration: underline;
}

.passenger_list_header_container {
    display: grid;
    grid-template-columns: 200px 350px 400px;
    /* align-items: center; */
    gap: 10px;
    color: #074D4D;
    font-weight: 600;
    /* margin-top: 20px; */
}

.passenger_list_sub_container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.passenger_item_main_container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.finance_invoice_choose_file_field {
    border: 1px solid #C0C0C0;
    border-radius: 5px;
    padding: 7px 10px;
    margin-top: 2px;
}

.finance_invoice_choose_file_text {
    font-family: 'Jost';
    font-size: 15px;
    font-weight: 400;
    color: #C0C0C0;
}

.finance_invoice_row_alignment {
    display: flex;
    align-items: center;
    gap: 10px;
}

.date_change_cancel_invoice_attachment {
    display: flex;
    align-items: center;
    gap: 5px;
}

.finance_invoice_column_alignment {
    display: flex;
    flex-direction: column;
    position: relative;
}

.comment_error_message_0 {
    /* position: absolute; */
    /* top: 35px; */
    color: red;
    font-size: 15px;
    margin-top: 5px;
    font-weight: 500;
    min-width: 200px;
}