.home_page_slider_menus_main_container{
    height: calc(100vh - 30px);
    /* width: 250px; */
    border-radius: 20px;
    background-color: #074D4D;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */

}
.home_page_slider_menus_main_container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
.slider_las_icon{
    width: 100%;
}
.slider_las_collapsed_icon{
    width: 50px;
    height: 50px;
}
.home_page_slider_sub_image_container{
    margin: 15px;
}
.home_page_menu_container{
    margin-top: 25px;
}