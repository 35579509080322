.custom_pagination_main_container{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.custom_pagination_sub_container_0{
    display: flex;
    align-items: center;
    gap: 10px;
}
.custom_pagination_sub_text_0{
    font-size: 14px;
    font-weight: 600;
    color: #5F5F5F;
}
.custom_pagination_per_page_count{
    padding: 5px 5px;
    border: 1px solid #106262;
    font-size: 14px;
    font-weight: 400;
    color: #074D4D;
    border-radius: 5px;
    outline: none;
}