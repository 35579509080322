.locations_module_main_container{
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
}
.locations_title{
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}
.locations_module_filter{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.locations_add_button{
    padding: 6px 15px;
    background-color: #ff8848;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    cursor: pointer;
}
.locations_table_main_container{
    margin-top: 20px;
}
.locations_action_edit{
    font-size: 20px;
    color: #FFAC27;
    cursor: pointer;
}
.locations_action_delete{
    font-size: 20px;
    color: #FF2222;
    cursor: pointer;
}
.locations_action_delete{
    font-size: 20px;
    color: #FF2222;
    cursor: pointer;
}
.locations_action_view{
    font-size: 20px;
    color: #046aa5;
    cursor: pointer;
}