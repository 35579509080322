.reset_password_main_container{
    position: relative;
    width: 100vw; /* Set your desired width */
    height: 100vh; /* Set your desired height */
    overflow: hidden;
}
.reset_password_main_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/background_image.png');
    background-size: cover;
    background-position: center;
    /* opacity: 0.6; */
    z-index: 1;
    overflow: hidden;
}

.reset_password_main_container > * {
    position: relative;
    z-index: 2;
}
.reset_password_sub_container{
    background-color: rgba(0, 0, 0, 0.452);
    width: 100vw; 
    height: 100vh;
    position: relative;
}
.reset_password_form_main_container{
    position: absolute;
    background-color: white;
    top: 50%;
    left: 60%;
    transform: translateY(-50%);
    min-width: 450px;
    padding: 30px;
    border-radius: 10px;
}
.reset_password_form_sub_container_1{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reset_password_form_las_logo{
    width: 220px;
    height: 50px;
}
.reset_password_form_m2_logo{
    width: 70px;
    height: 60px;
    object-fit: contain;
}
.reset_password_form_sub_container_2{
   margin: 20px 0px;
}
.reset_password_form_heading_title{
    font-size: 20px;
    font-weight: 600;
    line-height: 34.68px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #000000;
}
.reset_password_form_sub_title{
    font-size: 14px;
    font-weight: 500;
    line-height: 23.12px;
    text-align: left;
    color: #000000BF;
}
.reset_password_label{
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0px;
    text-align: left;
    color: #000000;
}
.reset_password_input_container{
    padding:8px;
    margin-bottom: 10px;
    border: 1px solid #C9C9C9;
    border-radius: 5px;
}
.reset_password_input{
    width: 100%;
    border: none;
    outline: none;
}

.reset_password_form_sign_up_container{
    margin: 20px 0px;
    padding:10px;
    background-color: #074D4D;
    border-radius: 10px;
    cursor: pointer;
    width:100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.12px;
    color: white;
    border: none;
}