.manager_level_modal_form_single_row_alignment{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.manager_level_modal_form_reset_button{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.manager_level_modal_form_update_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.comment_error_message{
    color: red;
    font-size: 15px;
    margin-top: 5px;
}
.manager_level_modal_label{
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #767676;
    margin-bottom: 8px;
}