.clients_module_main_container {
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
}

.clients_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}

.client_module_filter {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.client_add_button {
    padding: 6px 15px;
    background-color: #ff8848;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    cursor: pointer;
}

.client_table_main_container {
    margin-top: 20px;
}

.client_action_edit {
    font-size: 20px;
    color: #FFAC27;
    cursor: pointer;
}

.client_action_delete {
    font-size: 20px;
    color: #FF2222;
    cursor: pointer;
}

.user_action_view {
    font-size: 20px;
    color: #046aa5;
    cursor: pointer;
}