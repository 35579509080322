.slider_menu_main_component {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    padding: 15px 15px;
    cursor: pointer;
    border-radius: 10px;
}

.slider_menu_sub_component {
    display: flex;
    align-items: center;
    gap: 10px;
}

.slider_dropdown_icon {
    font-size: 20px;
    color: white;
}

.slider_menu_icon {
    width: 22px;
    height: 22px;
}

.slider_menu_text {
    font-size: 17px;
    font-weight: 500;
    line-height: 26.01px;
    color: white;
}

.slider_submenu_item {
    margin: 5px;
    padding:8px 10px 8px 38px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    cursor: pointer;
}

.slider_submenu_item_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    color: white;
}