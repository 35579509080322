.users_filter_sub_container{
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18% 10%;
    gap: 20px;
}
.user_filter_actions{
    display: flex;
    align-items: center;
    gap: 10px;
}

.user_filter_action_reset{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    height: 100%;
    padding: 0px 18px;
    cursor: pointer;
}
.user_filter_action_search{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    height: 100%;
    padding: 0px 18px;
    cursor: pointer;
}