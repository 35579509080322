.privilages_module_main_container {
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
    max-height: 100%;
    overflow-y: auto;
}

.privilages_module_sub_container_0 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.privilage_form_single_row_alignment {
    display: flex;
    align-items: center;
    gap: 15px;
}

.privilages_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    color: #434343;
}

.privilage_form_reset_button {
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
}

.privilage_form_save_button {
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.privilage_form_main_legend {
    position: absolute;
    text-align: center;
    width: 100px;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color: #074D4D;
}

.privilage_form_label {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #767676;
    margin-bottom: 8px;
}

.privilages_module_sub_container_1 {
    margin-top: 30px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 40px;
}

.privilages_module_sub_container_2 {
    max-width: 250px;
}

.privilage_module_wise_container {
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
}

.privilage_sub_legend {
    position: absolute;
    text-align: center;
    padding: 0px 20px;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    color: #074D4D;
}

.privilage_module_wise_main_container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 30px;
}

/* .privilage_actions_container { */
/* display: flex;
    align-items: center; */
/* justify-content: space-between; commented by sreeraj 24-10-24 for space issue on privilieges */
/* gap: 10px; */
/* } */

/* .privilage_actions_sub_container {
    display: flex;
    align-items: center;
    gap: 10px;
} */

/* .privilage_actions_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.privilage_actions_sub_container {
    flex: 1 1 calc(20% - 10px);
    max-width: calc(20% - 10px);
    box-sizing: border-box;
} */
.privilage_actions_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Creates exactly 5 items per row */
    gap: 10px;
    /* Space between items */
    align-items: center;
    /* Vertically aligns items */
}

.privilage_actions_sub_container {
    display: flex;
    align-items: center;
    /* Aligns the checkbox and text vertically */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* Optional: Add rounded corners */
}