.home_page_main_container{
    background-color: #f3f3f3;
    padding: 15px;
}
.home_page_sub_container{
    display: flex;
}
.home_page_sub_header_and_component_container{
    position: relative;
    flex-grow: 1;
}
.home_page_components_loading_container{
    margin: 20px 0px 0px 20px;
}