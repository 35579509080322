.no_data_container{
    position: relative;
    width: 100%;
    height: 200px;
    text-align: center;
    border: 1px solid rgb(252, 252, 252);
  }
  .no_data_sub_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .no_data_image{
    width: 250px;
    height: 180px;
  }
  .custom_table_mouse_over_popup{
    border: 1px solid rgb(238, 238, 238);
    border-radius: 10px;
    background-color: rgb(252, 252, 252);
    position: absolute;
    z-index: 100;
    width: 96%;
    padding: 5px 8px;
  }
  .custom_table_mouse_over_popup_sub_text_container{
    display: flex;
    align-items: center;
  }
  .custom_table_mouse_over_popup_sub_text_label{
    font-size: 15px;
    font-weight: 500;

  }
  .highlight_total_amount_value{
    font-size: 18px;
    font-weight: bold;
  }