.user_form_super_container {
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
}

.user_form_main_container {
    margin-top: 30px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
   
}
.user_form_sub_container_0{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
}
.user_form_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}

.user_form_sub_container {
    display: flex;
    justify-content: space-between;
}

.user_form_single_row_alignment {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user_form_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34.68px;
    text-align: left;
}
.user_form_back{
    cursor: pointer;
    font-size: 20px;
}
.user_form_reset_button{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.user_form_save_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.user_form_label{
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #767676;
    margin-bottom: 8px;
}