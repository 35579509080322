.financial_level_invoice_modal_form_single_row_alignment {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.financial_level_invoice_modal_form_reset_button {
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.financial_level_invoice_modal_form_update_button {
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.financial_level_invoice_download_action {
    color: green;
    font-size: 15px;
    margin-top: 5px;
}

.financial_level_invoice_modal_label {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #767676;
    margin: 5px 0px;
}

.financial_level_invoice_info_attchment_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.downoad_action_text {
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
}