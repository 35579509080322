.custom_card_v1_main_container{
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}
.custom_card_v1_sub_container{
    /* display: flex;
    align-items: center; */
    padding: 8px;
    border-radius: 10px;
    position: relative;
}
.card_image{
    position: absolute;
}
.custom_card_v1_sub_container_text{
    flex-grow: 1;
    text-align: center;
}

.custom_card_v1_sub_text{
    font-size: 18px;
    font-weight: 600;
    line-height: 33.61px;
    color: white;
}