.no_data_container{
    position: relative;
    width: 100%;
    height: 200px;
    text-align: center;
    border: 1px solid rgb(252, 252, 252);
  }
  .no_data_sub_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .no_data_image{
    width: 250px;
    height: 180px;
  }
  .custom_check_box_color{
    accent-color:  #074D4D;
}