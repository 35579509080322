.flight_ticket_date_change_main_container {
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
    margin-bottom: 30px;
}
.flight_ticket_date_change_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}
.flight_date_change_passenger_count{
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    color:#074D4D;
    align-items: center;
    margin: 8px 0px;
}
.flight_date_change_action{
    font-size: 15px;
    color:#074D4D;
    cursor: pointer;
    text-decoration: underline;
}

.flight_date_change_action_diabled{
    font-size: 15px;
    color:#919191;
    cursor: pointer;
    pointer-events:none;
}

.flight_ticket_date_change_form_sub_container{
    display: grid;
    grid-template-columns: auto 40%;
    gap: 20px;
}
.flight_ticket_date_change_row_alignment {
    display: grid;
    grid-template-columns: 250px auto;
    margin-bottom: 10px;
}
.flight_ticket_date_change_label_total_text{
    font-size: 16px;
    font-weight: 500;
}

.flight_ticket_date_change_remark_container {
    margin-top: 20px;
    position: relative;
    border: 1px solid #074D4D;
    padding: 2px;
}

.flight_ticket_date_remark_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 17px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}

.flight_ticket_date_main_container {
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
    margin-bottom: 30px;
}
.flight_ticket_date_form_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}

.flight_ticket_date_single_row_alignment{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.flight_ticket_date_cancel_form_reset_button{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.flight_ticket_date_cancel_form_save_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    text-align: center;
    padding: 3px 20px;
    cursor: pointer;
}
.flight_date_change_form_label_required{
    color: red;
}