.general_settings_tabs_main_container{
    display: flex;
    align-items: center;
    gap: 10px;
}

.un_selected_general_settings_tabs{
    padding: 6px 10px;
    border: 1px solid #074D4D ;
    color: #074D4D;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
}


.selected_general_settings_tabs{
    padding: 6px 10px;
    background-color: #074D4D ;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
}
.general_settings_module_main_container{
    background-color: white;
    height: calc(100vh - 165px);
    padding: 20px;
}