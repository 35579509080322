.flight_date_change_confirm_main_container {
    position: relative;
    border: 1px solid #074D4D;
    padding: 20px;
    margin-bottom: 30px;
}
.flight_date_change_confirm_main_legend {
    position: absolute;
    text-align: center;
    left: 20px;
    top: -16px;
    background-color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.79px;
    color:#074D4D;
    padding: 0px 10px;
}
.flight_date_change_confirm_passenger_count{
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    color:#074D4D;
    align-items: center;
    margin: 8px 0px;
}
.flight_date_change_confirm_passenger_narration{
    font-size: 16px;
    font-weight: 600;
    color:#000000;
    margin: 10px 0px;
}
.flight_date_change_confirm_main_fair_container {
    display: grid;
    grid-template-columns: 12% auto;
    gap: 20px;
    margin-bottom: 10px;
}

.dummy_border_line {
    margin-top: 10px;
    width: 100%;
    height: 0px;
    border: 1px dashed black;
}

.flight_date_change_confirm_text_1 {
    font-size: 16px;
    font-weight: 400;
    color: #303030;
}

.flight_date_change_confirm_text_2 {
    font-size: 17px;
    font-weight: 500;
    color: #303030;
}
.flight_date_change_confirm_value_text_1{
    font-size: 16px;
    font-weight: 400;
    color: #303030;
    text-align: left;
}
.flight_date_change_confirm_value_text_2{
    font-size: 17px;
    font-weight: 500;
    color: #303030;
    text-align: left;
}
.flight_date_change_confirm_single_row_alignment{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.flight_date_change_confirm_close_button{
    border: 1px solid #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.12px;
    text-align: left;
    color: #074D4D;
    background-color: white;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.flight_date_change_confirm_save_button{
    background-color: #074D4D;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    text-align: center;
    padding: 3px 20px;
    cursor: pointer;
}