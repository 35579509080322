.not_found_page_main_container{
    width: calc(100vw - 40px) ;
    height:calc(100vh - 40px)  ;
    margin: 20px;
    border-radius: 10px;
    background-color: #074D4D;
    position: relative;
}
.not_found_page_sub_container{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
}
.not_found_page_sub_container_1{
    display: flex;
    align-items: center;
    gap: 10px;
}
.not_found_page_sub_container_2{
    position: relative;
}
.not_fount_text_1{
    font-size: 360px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF0D;
}
.not_fount_text_2{
    position: absolute;
    font-size: 220px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    left: 20px;
    top: 260px;
}
.not_fount_text_3{
    position: relative;
    font-size: 40px;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
}
.not_fount_text_with_back_container{
    position: absolute;
    right: 320px;
}
.not_fount_text_with_back{
    display: flex;
    align-items: center;
    gap:10px;
    cursor: pointer;
}
.not_found_go_back_text{
    font-size: 22px;
    font-weight: 700;
    color: #F5F5F5;
}
.not_found_go_back_icon{
    font-size: 22px;
    color: #F5F5F5;
}